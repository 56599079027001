<template>
	<main></main>
</template>

<script>
export default {
	name: "title"
}
</script>

<style scoped>

</style>